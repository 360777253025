.sidebar {
   background: $lightestgray;
   padding: 1.5rem;
   margin: 1.5rem 0;
   position: relative;
   @include tablet {
      background: $clear;
      padding: 0;    
   }
   @include desktop {
      margin: 0 0 2rem 0;
   }
   &-header {
   	background: $clear;
   	color: $red;
   	font-family: $nav;
      letter-spacing: -.5px;
   	padding: .25rem .5rem;
   	text-transform: uppercase;
   	text-align: left;
   	@extend %slide-up;	
   	@extend %headline6;	
   	&.reveal {
   		@extend %slide-up-reveal;	
   	}
   	&-link {
         display: block !important;
         color: $red !important;
         transition: $slow;
         font-weight: 900;
         line-height: 1;
         text-decoration: none !important;
         &::after {
            content: "";
            width: 80%;
            height: 4px;
            position: absolute;
            bottom: -.5rem;
            left: -.5rem;
            z-index: -1;
            background: $red; 
         }
         &:hover {
            background: $clear !important;
         }
   	}
   }  
   &-list {
      padding: 1rem 0;
      &-item {
         @extend %slide-up;	
         &.reveal {
            @extend %slide-up-reveal;	
         }      
      }
      &-link {
         @extend %headline7;	
         color: $medgray !important;
         letter-spacing: -.5px;
         font-family: $nav;
         font-weight: 700;
         transition: $slow;
         padding: .4rem;
         line-height: 1.1;
         text-transform: uppercase;
         text-align: left;
         display: block !important;
         text-decoration: none !important;
         &:hover {
            color: $black !important;
            background: $clear !important;
         }
         &:focus {
            color: $brightred !important;
         }  
         &.active {
            color: $black !important;
            &::after {
               content: " \00BB";
               color: black;
               font-size: 1.25rem;
               line-height: 1;
               position: relative;
               bottom: -1px;
            }
         }
      }
   }
   &::after {
      content: "";
      width: 1px;
      height: 500px;
      position: absolute;
      top: -0.5rem;
      right: -1rem;
      z-index: -1;
      background: $lightgray; 
   }
}    

.sidebar-list-subnav {
  border-top: 0;
 	width: 100%;
 	list-style: none;
 	margin: 0;
 	padding: .25rem 1rem;
 	position: relative;
 	z-index: 1000;
 	transform: none;
 	transition: none;
 	&:before {
  		@include size(0, 0);
  		@include absolute(left 50% bottom 100%);			
  		z-index: 200;
  		border: solid transparent;
  		content: " ";
  		pointer-events: none;
  		border-color: rgba(darken($white, 10%), 0);
  		border-bottom-color: $white; 
  		border-width: .5rem;
  		margin-left: -.5rem;	
    display: none;
 	}		
 	&-li {
  		padding: 0;
  		margin: 0;
  		display: block;
    border-bottom: 1px dotted $gray;
    &:last-child {
       border-bottom: 0;
    }
    &.subnav2 {
     border: none;
    }
 	}
 	&-link {
  		display: block !important;
  		width: 100%;
  		padding: .25rem .5rem;
  		color: $darkgray;
  		text-transform: none;
  		text-decoration: none !important;
  		font-family: $body;
  		font-size: 0.9rem;
  		font-weight: 500;
  		text-align: left;
  		transition: $slow;
  		&:hover {
     color: $white !important;
  			background: darken($red, 15%) !important;
  		}
  		&:focus {
     color: $white !important;
  			background: darken($red, 50%) !important;
  		}		
  		&.subnav2 {
  		  color: $darkgray;
  		}
 	}
 	&.subnav2 {
 	  margin: 0 0 1rem 0 !important;
 	}
}


.sidebar-promos {
   &-container  {
      background: $darkgray;
   }
   &-item {
      &-pic {
         width: 100%;
         display: block;
      }
      &-img {  
         width: 100%;
         max-width: 100%;
         height: auto;
         display: block;
      }
      & a {
         text-decoration: none !important;
      }
      &-blurb {
         padding: 1rem;
      }
      &-headline {
         @extend %headline5;  
         color: $white;
      }
      &-text {
         font-size: 1.0rem;
         color: $white;
         & a {
            color: $white;
            text-decoration: underline !important;
         }
         &.text-dark-gray a { color: $darkgray !important;}
         &.text-black a { color: $black !important;}
         &.text-white a { color: $white !important;}
         &.text-red a { color: $red !important;}
         &.text-yellow a { color: $yellow !important;}
      }
   }
}

.wall-grid {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px; 
  padding-top: .25rem;
  & .sidebar-list-link {
    text-align: center;
    background: $lightgray;
    &.active {
      background: $red !important;
      color: $white !important;
      &:after {
        display: none;
      }
    }
  }
}
