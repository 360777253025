.basic-text {
  text-align: left;
  @extend %basic-text;
  max-width: 64rem;
  margin: 0 auto;
	@extend %slide-up;		
  &.reveal {
  	@extend %slide-up-reveal;		
  }
  ul, ol {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }    
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: $primary;
    transition: $slow;
    &:hover {
      color: $secondary;
    }
  }
  &-headline {
    @extend %headline5;
    color: $gray;
  }
  &.add-padding {
    @include tablet {
      padding: 1rem 1rem 1.5rem;
    }
  }
	&.top_margin {
	  @extend %block-margin-top;
	}	
	&.bottom_margin {
	  @extend %block-margin-bottom;
	}		  
}

.sidebar_box {
  background: $lightestgray;
  padding: 1rem;
  width: 100%;
  margin: 1rem 0;
  float: none;
  @include tablet {
    width: 40%;
    margin: 0 0 1.5rem 1.5rem;
    float: right;
  }
}
