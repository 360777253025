@import "globals/globals";
@import "pages/basic-blocks";
@import "pages/basic-text"; 
@import "pages/text-layer";   
@import "pages/child-list"; 
@import "pages/content-carousel";
@import "pages/donation-form";   
@import "pages/expander";
@import "pages/featured-items";
@import "pages/folder-list";
@import "pages/grids";   
@import "pages/hero";   
@import "pages/latest-feed";   
@import "pages/menu-box";   
@import "pages/meta";   
@import "pages/news-list";   
@import "pages/photo-gallery";  
@import "pages/podcast";   
@import "pages/press";   
@import "pages/related-news";  
@import "pages/section-front";
@import "pages/share";
@import "pages/sidebar-list";   
@import "pages/story-layers";   
@import "pages/team";   
@import "pages/video";   

.basic-wrap {
	text-align: left;
	margin: 0 auto;
	@extend %block-padding-small;	
	transition: $slow;
	@extend %basic-text;	
	max-width: $tablet;
}
#main_content {
   margin-top: 70px !important;
   position: relative;
   display: block;
   @include tablet {
     margin-top: auto;
   }
}
