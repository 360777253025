#team-layer {
}

.team-block-container {
 margin: 0 0 4rem;
 display: flex;
 flex-wrap: wrap;
 justify-content: space-evenly;
 align-content: flex-start;
 .executive {
 }
 .vp {
 }
 .director {
 }
}

.team-member {
 padding: 1rem;
 width: 100%;
 &-link {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center; 
  padding: 1rem;
  text-decoration: none !important;
  &:hover {
   background: $lightgray !important;
  }
 }
 
 &-photo {
  width: 7rem;
  height: 7rem;
  &-img {
   width: 7rem;
   height: 7rem;
   background-size: cover;
   background-repeat: no-repeat;
   -webkit-border-radius: 50%;
   border-radius: 50%;
  }
 }
 
 &-text {
  
  &-headline {
 		font-family: $headline2;
 		font-size: 1.5rem;
 		line-height: 1.1;
 		color: $primary;
 		margin-bottom: .5rem;
 		@extend %headline2;	 
  }
  
  &-title {
   color: $medgray;
 		line-height: 1.1;
 		text-transform: uppercase;
 		font-size: .9rem;
 		font-weight: 700;
 		display: block;
  }
  
  &-subtext {
   color: $black;
 		font-size: .9rem;
 		line-height: 1.2;
 		margin-top: .25rem; 
 		display: block;
  }
 
 } 
 
}

.team-block-container.executive .team-member,
.team-block-container.vp .team-member {
 display: flex;
 flex-direction: row;
 flex-wrap: nowrap;
 justify-content: flex-start;
 align-items: center;
 @include desktop {
  width: 50%;
 }
 &-text {
  padding: 0 0 0 1.0rem;
  width: inherit;
  &-headline {
 		font-family: $headline2;
 		font-size: 1.65rem;
  }
  
  &-title {
 		font-size: 1rem;
  }
  
  &-subtext {
 		font-size: 1rem;
  }
 
 } 
}

.team-block-container.director .team-member {
 @include desktop {
  width: 33%;
 }
}
