.donations {
  margin: 0 1rem 1.5rem;
  padding: 1.5rem;
  background: $red;
  @include tablet {
    margin: 0 1rem 2rem;
    padding: 2rem;
  }
  &-headline {
    @extend %headline3;
    color: $white;
    position: relative;
    display: inline-block;
    width: 100%;
    @tablet {
      width: auto;
    }
    & span {
      position: relative;
      z-index:1;
    }
  }
  &-text {
    color: $white;
    font-size: 1.1rem;
    line-height: 1.3rem;
    @include tablet {
      font-size: 1.25rem;
      line-height: 1.6rem;
    }
    & p {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.donations-form {
  &-grid {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 50%;
    display: grid;
    align-content: flex-start;
    grid-gap: 0;
    grid-template-columns: repeat(1, 1fr);
    @include tablet {
      margin: 1.5rem auto 0;
      width: fit-content;
      display: flex;
      align-content: flex-start;
      grid-gap: 1rem;
      grid-template-columns: repeat(5, 1fr);
    }
  }
  &-item {
    position: relative;
    display: block;
  }
  &-btn {
    width: 100%;
    position: relative;
    display: inline-block;
    font-weight: 900;
    line-height: 1.5;
    color: $gray;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: $white;
    border: 2px $white solid;
    padding: 0.45rem 1.5rem;
    font-size: 1.5rem;
    transition: $slow;
    @include tablet {
      width: auto;
    }
    &:hover {
      background: $lightblue;
      color: $white;
    }
    &.submit {
      margin-top: 1rem;
      background: none;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      border: 3px $white solid;
      padding: 0.45rem 1.5rem !important;
      transition: $slow;
      border-radius: 100px;
      @include tablet {
        margin-top: 0;
      }
      &:hover {
        color: $red;
        background: $white;
      }
    }
    &-check {
      position: relative;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
      top: 50%;
      left: -40%;
      z-index: 100;
      width: 0;
      display: inline;
      opacity: 0;
      transition: $slow;
      @include tablet {
        top: -25px;
        left: 48%;
      }      
      &:checked {
        opacity: 1;
        &:after {
          content: "\2713";
          color: $white;
          top: -7px;
          left: -7px;
          position: absolute;
          font-size: 20px;
          padding: 1px 0 0 0;
          background: $lightblue;
          border: 2px $white solid;
          width: 28px;
          height: 28px;
          border-radius: 100px;
          text-align: center;
        }
      }
    }
  }
}
