.text-layer {
	text-align: left;
	margin: 0 auto;
	@extend %block-padding;	
	opacity: 1;
	transition: $slow;
	@extend %basic-text;
	@extend %clearfix;	
	position: relative;
	@include phone {
	  padding-top: 1.5rem;
	  padding-bottom: 1.5rem;
	}
	&.small-padding {
  	@extend %block-padding-small;	
	}
	&.center {
	  text-align: center;
	}
	&.no_top_padding {
	  padding-top: 0 !important;
	}	
	&.no_bottom_padding {
	  padding-bottom: 0 !important;
    p:last-of-type {
      margin-bottom: 1rem;
    	@include tablet {
  			margin-bottom: 1.5rem;	
    	}
    	@include desktop {
  			margin-bottom: 1.4rem;	
    	}	
    	@include md {
  			margin-bottom: 1.6rem;	
  		
    	}		
    	@include lg {
  			margin-bottom: 1.8rem;	
    	}			
    	@include xl {
  			margin-bottom: 2rem;	
    	}		
    }
	}	
	&.top_margin {
	  @extend %block-margin-top;
	}	
	&.bottom_margin {
	  @extend %block-margin-bottom;
	}			

	&-wrap {
  	max-width: $desktop;
  	margin: 0 auto;
  	position: relative;
		@extend %clearfix;	
	  >*:last-child {
	    margin-bottom: 0;
	  }
   &.grid-33 {
     max-width: $desktop-medium;
   }

	}
	&-headline {
		color: $primary;
		text-align: center;
		line-height: 1;
		margin-bottom: 1rem;
    @extend %headline2;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }   
    &.trigger {
      @extend %slide-up;
      &.reveal {
        @extend %slide-up-reveal
      }
    }
	}


   h1 { 
      position: relative;
      font-family: $headline;
      margin-bottom: .5em;
   }


	&.reveal {
		opacity: 1;				
	}
	a {
	  color: $red;
      text-decoration: underline;
	  transition: $basic;
	  display: inline;
	  &:hover {
	    color: $black;
       background: $lightblue;
	  }
	}
	.button-block {
	  margin-top: 1rem;
	}
  ul, ol {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.5rem;
    }
    @include desktop {
      margin-bottom: 2rem;
    }    
  }
  p {
    &:last-of-type {
    }
  }
  .disclaimer {
    font-size: .8rem;
    text-align: center;
    margin-top: 1rem;
    display: block;
    @include tablet {
      margin-top: 1.5rem;
    }
    @include desktop {
      margin-top: 2rem;
    }       
  }  
  .video-player {
    margin: 0 auto;
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 1.25rem;
    }
    @include desktop {
      margin-bottom: 1.75rem;
    }   
    @include lg {
      margin-bottom: 2rem;
    }     
  }
  &-image {
    position: relative;
    width: 100%;
    height: auto;
    @extend %basic-text;
    &-src {
      width: 100%;
      vertical-align: middle;
    }
    &.full-width {
      margin: 0 auto;
      margin-bottom: 1rem;
      max-width: $tablet;
      @include tablet {
        margin-bottom: 1.5rem;
        margin-top: 2rem;
      }
      @include desktop {
        margin-top: 3rem;
      }   
      @include xl {
        margin-top: 4rem;
      }        
    }
    &.floating-image {
      max-width: 100%;
      margin-bottom: 1rem;
      @include tablet {
        max-width: 50%;
        margin-bottom: 0;
        &.floating-image-left {
          float: left;
          margin: 0 1rem 1rem 0;
          @include tablet {
            margin: 0 1.5rem 1rem 0;
          }
          @include desktop {
            margin: 0 2rem 1rem 0;
          }          
        }        
      }
      @include tablet {
        max-width: 50%;
        &.floating-image-right {
          float: right;
          margin: 0 0 1rem 1rem;
          @include tablet {
            margin: 0 0 1rem 1.5rem;
          }
          @include desktop {
            margin: 0 0 1rem 2rem;
          }              
        } 
      }    
      &.intrinsic {
       max-width: none;
       width: auto;
       text-align: center;
       @include phone {
        float: none !important;
       }
       @include tablet {
        display: table !important;
       }
       .text-layer-image {
        &-src {
         margin: 0 auto;
         width: auto !important;
         max-width: 100%;
        }        
        &-caption {
         @include tablet {
          display: table-caption;
          caption-side: bottom;
          text-align: left;
         }
        }
       }
      }
    }
    &-caption {
     font-size: 70%;
     line-height: 1;
     padding: .25rem;
   		color: $gray;
   		@include desktop {
       padding: .25rem 0;
   		}
    }
  }
  &-embed {
    max-width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
   	opacity: 0;
   	transition: $slow;
    @include tablet {
      width: 40%;
      float: right;
      margin: 0 0 1rem 1.5rem;
    }
    @include desktop {
      width: 50%;
      margin: 0 0 1rem 2rem;
    }
    &-headline {
      margin-bottom: .5rem;
      font-family: $headline;
      font-weight: 600;
    }
    &.reveal {
      opacity: 1;
    }  
  }
 & img {
  width: 100% !important;
  height: auto !important;
  @include tablet {
    width: auto !important;
    height: auto !important;
    max-width: 40% !important;
  }
 }
}

article.clearfix {
   display: block;
   margin: 0 0 2rem 0;
   padding: 0 0 2rem 0;
   border-bottom: 1px $lightgray solid;
}

.list {
   margin: 2rem 0 1rem 0;
   display: flex !important;
   flex-wrap: wrap;
   flex-direction: row;
   align-items: stretch;
   align-content: stretch;
   
   &-item {
      flex: 1,1;
      width: 50%;
      margin: 0 0 2rem 0;
      &-link {
      }
      &-photo {
         display: block;
         margin: 0 1.5rem 0 0;
         & img {
            padding: 1px;
            width: 100%;
            border: .25rem solid $gray;
         }
      }
      &-text {
         display: block;
         padding: .25rem;
         & .headline {
            font-family: $headline;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 1.2em;
            margin: 0;
            padding: 0;
         }
         & .description {
            color: $black;
            display: block;
            font-size: 0.9em;
            font-weight: normal;
            line-height: 1.35em;
            padding: .4em 0;
         }
      }
   }
}

.list-thumbnail {
   display: block;
   margin-top: 2rem;
   & .item {
      display: table;
   }
   & h3 {
      margin-bottom: .5rem;
   }
   & .icon {
      float: left;
      border: 5px solid #cecece;
      margin: 0 1.5em 2em 0;
   }
   & p {
      font-size: 1.1rem;
      line-height: 1.5rem;
   }
}
.supporter-wrap {
  margin: 0 0 3rem 0;
  text-align: center;
}
.supporter-logo {
  max-width: 180px;
  max-height: 180px;
  padding: 1rem;
  display: block;
  vertical-align: middle;
  @include tablet {
    max-width: 240px;
    max-height: 240px;
    display: inline-block;
  }
  & img {
   width: 100% !important;
   height: auto !important;
  }
}

.memorial-bio .grid-layer-col {
  & div {
    display: block;
    float: none;
    clear: both;
    position: relative;
    & img {
      width: 200px !important;
      margin: 0 0 1rem 1rem;
      @include tablet {
        max-width: 400px !important;
        float: right;
        margin: 0 0 1.5rem 1.5rem;
        width: 400px !important;
      }
    }
  }
  & hr {
    clear: both;
    display: block;
    padding: 1rem 0;
    background: $white;
    border: 0;
  }
  .wall-outer {
    clear: both;
    font-size: 0;
    background: $lightgray;
    text-align: center;
    padding: 1.5rem;
    & .detail {
      margin-bottom: 2rem;
    }
    & img {
      width: 100% !important;
      float: none;
      max-width: 100% !important;
      margin: 0;      
    }
  }
}


.newsletter-signup-module {
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 1.5rem;
  display: block;
  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 0px;
  }
  @include desktop {
    grid-template-columns: 3fr 2fr;
  }
  &-text {
    font-size: 90%;
    & p { 
     margin: 0;
    }
    margin-bottom: 1rem;
    @include tablet {
     margin-bottom: 0;
    }
  }
  &-form {

    .join-form {
      position: relative;
      display: block;
      width: 100%;
      flex-direction: row;
      align-items: end;
      &.unsubscribe {
        max-width: $tablet;
        margin: 0 auto;
      }

      &-headline {
        @extend %headline3;
        color: $red;
        text-transform: none;
        margin: 0 auto;
        display: block;
        text-align: left;
        @include tablet {
        }  
      }
      &-txt {
        @extend %basic-text;
        color: $black;
        line-height: 1.3;
        margin-bottom: 1rem;
        @include desktop {
          margin-bottom: 1.5rem;
        }
      } 
      &-row {
      }
      &-item {
        padding: 0;
        position: relative;
        width: 100%;
        @include desktop {
          display: block;
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 1fr;
          grid-column-gap: .5rem;
          grid-row-gap: 0;
        }
        align-items: center;
        margin-bottom: .65rem;
      }
      &-label {
        font-size: 1.0rem;
        color: $black;
        display: block;
        cursor: pointer;
        text-align: left;
      }
      &-input {
        width: 100%;
        background: rgba($white, 1);
        padding: .35rem;
        font-family: $body;
        font-size: 1.0rem;
        color: $black;
        border-radius: 0 !important;
        outline: 0;
        cursor: pointer;
        transition: .5s all $slow-curve;
        &:hover {
          background: $red;
          color: $white;
        }
        &:focus {
          background: $red;
          color: $white;
        }
      }
      &-button {
        &-row {
          margin-top: .5rem;
          @include desktop {
            margin-top: 0;
          }
        }
        background: transparent;
        position: relative;
        outline: 0;
        border: 0;
        cursor: pointer;
        &-txt {
          color: $white;
          font-weight: 900;
          text-transform: uppercase;
          font-family: $button;
          font-size: 1.0rem;
          line-height: 1;
          padding: 1.1rem 2rem 1.1rem;
          display: block;
          position: relative;
          z-index: 10;
          white-space: nowrap;
          transition: color .1s $slow-curve;
          transition-delay: .3s;
        }
        &-bg {
          @include size(100%);
          background: $black;
          @include absolute(left 0 top 0);
          z-index: 1;
          display: block;
          overflow: hidden;
          transition: background .2s $slow-curve;
          transition-delay: .4s;
          border-radius: 50px;
          &:before, &:after {
            @include absolute(left 0 top 0);
            content:"";
            display: block;
            @include size(0, 100%);
            transition: .5s width $slow-curve;
          }   
          &:before {
            background: $red;
            z-index: 1;
          }
          &:after {
            background: $red;
            transition-delay: .15s;
            z-index: 10;
          }   
        }
        &:hover {
          .join-form-button {
            &-txt {
              color: $white;
            }
            &-bg {
              background: $red;
              &:before,
              &:after {
                width: 100%;
              }
              &:after {
              }     
            }
          }
        }
      }
      &-response {
        position: relative;
        background: none;
        padding: 1.25rem 1.25rem 3rem;
        /*border: 3px solid $blue;*/
        display: block;
        @include phone {
          width: calc(100% + 2rem);
          position: relative;
          left: -1rem;
        }   
        @include tablet {
          padding: 2rem 2rem 3rem;
        }  
        @include desktop {
          padding: 3rem;
        }
        @include md {
          padding: 3.5rem;
        }   
        &-headline {
          @extend %headline3;
          line-height: 1.2;
          margin-bottom: 1rem;
          @include tablet {
            margin-bottom: 1rem;
          }  
        }
        &-txt {
          @extend %basic-text;
          line-height: 1.3;
          margin-bottom: 1rem;
          @include desktop {
            margin-bottom: 1.5rem;
          }
        } 
      }
      strong.error {
        @include absolute(right .25rem top .47rem);
        background: $red;
        color: $white;
        padding: .25rem .25rem .1rem;
        font-size: .7rem;
        text-transform: uppercase;
        display: inline-block;
        font-weight: 400;
        border: 2px solid $red;
        text-transorm: uppercase;
      }
    }
   
  }
}

.social-extras-share {
    font-size: 2rem;
    margin: 1rem 0 0 0;
    position: relative;
    width: 100%;
    & .social-link {
      width: 40px;
      margin-right: 0.2rem;
      display: inline-block;
      border-radius: 0;
      padding: 0;
    }
    &.social-link svg {
      width: 100%;
      fill: $black;
    }
    & a:hover {
      background: none;
    }
    & .social-link:hover svg {
      fill: $red;
      background: none;
    }
}
