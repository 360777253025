.content-carousel {
  &-wrap {
  }

  &-list {
    position: relative;
  }
  
  &-item {
    &.slide {
    }
  }

  & .slick-dots {
    list-style: none;
    width: 100%;
    margin: 1rem auto 0;
    text-align: center;
    & li {
      display: inline-block;
      margin: .25rem;
      width: 30px;
      height: 30px;
      background: $lightgray;
      border-radius: 100%;
      & button {
        opacity: 0;
      }
      &.slick-active {
          background: $blue;
      }
      &:hover {
          background: $red;
      }
    }
  }
  
}

