.photo-gallery {
  margin: 2rem 0;
  max-width: $tablet-wide;
  &-wrap {
    border-radius: 10px;
    &.padding {
      padding: 1rem;
      @include tablet {
        padding: 2rem;
      }
    }
  }
  &-headline {
    margin: 0;
  }
  &-text {
   
  }
  &-list {
    position: relative;
  }
  
  &-item {
    &.slide {
     
    }
  }
  
  &-link {
    width: 100%;
    text-decoration: none !important;
  }
  &-img {
    width: 100%;
    height: auto;
  }
  &-caption {
    display: block;
    width: 100%;
    padding: .25rem;
    background: $darkgray;
    color: $white;
    text-align: center;
    font-size: .8rem;
  }
}


/* -------- Slide Controls -------- */

.slide-controls {
	cursor: pointer;
	font-size: 1.8em;
	position: absolute; 
	top: 30%; 
	transform: scale(1.5);
	z-index: 101;
	color: #fff;
	opacity: 1;
	-webkit-transition: all .2s ease-in-out;
	-moz-transition:    all .2s ease-in-out;
	-o-transition:      all .2s ease-in-out;
	-ms-transition:     all .2s ease-in-out;
	transition:         all .2s ease-in-out;   
	-moz-transform:    translateY(-50%);
	-ms-transform:     translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform:         translateY(-50%); 
	@include tablet {
   font-size: 1.4em; 
   top: 50%; 
   opacity: .8;
	}
}  
.slide-controls:hover {
	opacity: 1;	
}
.prev-slide { left: 1em; fill: $white;}
.next-slide { right: 1em; fill: $white;}
