.news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-content: flex-start;
  &-title {
    color: $red;
  }
  &-footer {
    text-align: center;
  }
  &-item {
    width: 100%;
    padding: 1.5rem;
    transition: $slow;
    display: inline-block;
    opacity: 0;
    transform: translateY(4rem);
    &.reveal {
      opacity: 1.0;
      transform: translateY(0);
    }

    @include tablet {
      width: 50%;
    }
    @include desktop {
      width: 33%;
    }
    &:hover {
      background: $lightestgray;
    }
    &-link {
      text-decoration: none !important;
    }
    &-date {
      color: $gray;
      font-size: .8rem;
      margin-bottom: .25rem;
      display: block;
      text-transform: uppercase;
      font-weight: 700;
    }
    &-headline {
      font-family: $headline2;
      color: $black;
      margin-bottom: 0;
      padding-bottom: .5rem;
      font-size: 1.45rem;
      line-height: 1.25;
      position: relative;
    }
    &-description {
      display: block;
      font-family: $body;
      color: $darkgray;
	     font-weight: 300;
	     font-size: 70%;
    }
  }
}



.news-list-old {
  text-align: left;
  margin-top: 1rem;
  background: $lightestgray;
  @include tablet {
    margin-top: 1.25rem;
  }
  @include desktop {
    margin-top: 1.75rem;
  }
  @include lg {
    margin-top: 2rem;
  }  
  &:first-of-type {
    margin-top: 0;
  }
  &-header {
		background: $darkblue;
		color: $white;
		font-family: $headline;
		padding: .75rem .75rem;
		text-transform: uppercase;
		margin-bottom: .5rem;
		@extend %slide-up;	
		@include desktop {
		  font-size: 1.0rem;
		}
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-item {
		@extend %slide-up;	
		&.reveal {
			@extend %slide-up-reveal;	
		}
	}
	&-title {
		font-family: $body;
	   font-weight: 300;
		@extend %headline6;	
		transition: $slow;
	}
	&-date {
	  text-transform: uppercase;
	  font-size: 80%;
	}
	&-link {
    color: $blue;
    transition: $slow;
		@extend %slide-up-reveal;	
		font-weight: 300;
		display: block;
		padding: .4rem;
    @include tablet {
		  padding: .6rem;
    }
    @include lg {
		  padding: .75rem;
    } 			
    &:hover {
      .news-list {
        &-title {
          color: $primary;
        }
      }
    }
		&:focus {
    	box-shadow: 0 0 0 2px $green;
      .news-list {
        &-title {
          color: $primary;
        }
      }    	
		}     
	}
}

.news-psas {
  @extend %block-padding-top-small;
}

.news-pr-newsletters {
  @extend %block-padding-top-small;
  @include tablet {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-content: flex-start;
    & .news-pr {
      width: 50%;
      &.newsletters {
        width: 45%;
        margin-left: 5%;
      }
      & .news-list {
        display: block;
        & .news-list-item {
          width: 100%;
        }
      }
    }
  }
}

.news-events {
  @extend %block-padding-top-small;
}
