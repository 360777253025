.related-news {
  background: $lightestgray;
	width: 100%;
	padding: 2rem 1rem 1rem;
	margin: 0 auto;
	@include tablet {
		padding: 2rem 1rem;
	}	
	@include desktop {
		padding: 3rem 1rem;
	}	
	@include lg {
		padding: 5rem 1rem;
	}	
	&-headline {
		font-family: $headline;			
		color: $secondary;
		font-size: 1.5rem;
		margin: 0 auto .75rem auto;
		transition-delay: .2s !important;
    @extend %slide-up;
		@include tablet {
			font-size: 1.5rem;
		}		
		@include desktop {
			font-size: 2rem;
			margin: 0 auto 1rem auto;				
		}	
		@include desktop {
			font-size: 2.25rem;
		}		
		&.reveal {
      @extend %slide-up-reveal;
		}
		&-link {
		  color: $gray;
		  transition: $slow;
		  &:hover {
		    color: $primary;
		  }
		}
	}		
	&-grid {
		margin: 0 auto;
		@include tablet {
			max-width: $max-width;
			padding: 0 0;			
			display: flex;
			flex-wrap: nowrap;
			justify-content: center;
			align-content: flex-start;			
		}
	}
	.news-card {
		margin: 1rem auto;;
		padding: 0;
		@include tablet {
			margin: 1rem;
			width: calc(33.33% - 1rem);
			&:nth-child(1) {
				transition-delay: .2s;
			}
			&:nth-child(2) {
				transition-delay: .4s;
			}	
			&:nth-child(3) {
				transition-delay: .6s;
			}			
		}				
	}		
}
