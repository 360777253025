.hero {
 	background-color: $darkgray;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
 
  /* Reverse Column Order */
  -webkit-flex-flow: column-reverse;
  flex-flow: column-reverse;

  @include size(100%, auto);	
	 position: relative;
  margin: 0 auto;
	 @include tablet {
	 }
  @include desktop {
    -webkit-flex-flow: initial;
    flex-flow: initial;
		  @include size(100%, 400px);
    max-width: $desktop-medium;
	 }
  @include md {
    max-width: $desktop-large;
	 }

  &-text {
		max-width: $max-width;
		padding: 0;	
		width: 100%;
		z-index: 200;
  opacity: 1;
  &.reveal {
    opacity: 1.0;
  }
		@include tablet {
		}		
		@include desktop {
			 padding: 0 2rem;
  		@include absolute(left 50% bottom 0rem);
  		transform: translateX(-50%);
			 bottom: -2rem;		
    &.reveal {
  	   @include absolute(left 50% bottom -3rem);
    }
		}	
		@include ie {
			width: auto !important;	
		}	
		@include edge {
			width: auto !important;	
		}
      &.align-bottom {
         top: initial;
         bottom: 2.5rem;
      }
	}
	&-pre-headline {
		background: none;
		color: $white;
		font-family: $nav;
		display: table;
		margin: 0 auto;
		text-transform: uppercase;
		padding: .2rem .4rem;
		margin-bottom: 0.75rem;
		opacity: 1;
		position: relative;
		z-index: 200;
      @extend %headline5;
      &:hover {
         color: $white;
      }
      &:focus {
         color: $white;
         box-shadow: 0 0 0 4px $white;
      }	  
      &::after {
         content: "";
         width: 104%;
         height: 4px;
         position: absolute;
         bottom: -2px;
         left: -2%;
         z-index: -1;
         background: $white; 
      }	  
	}
	&-headline {
		color: $white;
		font-family: $headline;
		line-height: 1;
		opacity: 1;
      position: relative;
      @extend %headline2;
      &-link {
         color: $white;
      }
	}
   &.no-photo {
      @include size(100%, 15vh);
   	@include tablet {
   		@include size(100%, 110px);
   	}
      @include desktop {
   		@include size(100%, 135px);
         max-width: $desktop-medium;
   	}
      @include md {
      	@include size(100%, 160px);
         max-width: $desktop-large;
   	}

   }
   &.no-photo .hero-text {
      top: initial;
      bottom: 0;
   }
   &.no-photo .hero-headline::after {
      display: none;
   }
	&-blurb {
		color: $text;
      max-width: $max-width-text;
		font-size: 1.1rem;
		line-height: 1.2;
		margin: 1.5rem auto 0;
      background: #e8e8e8;
      padding: 2rem;
      opacity: 1;
		@include tablet {
			font-size: 1.1rem;
		}		
		@include desktop {
			font-size: 1.2rem;
		}		
		p {
			margin-bottom: 0;
		}
	}	

   &-grad {
   	@include size(100%, 100%);	
  		@include absolute(left 0 top 0);
  		opacity: 1;
  		z-index: 2;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
    background: -moz-linear-gradient(top,  rgba(34,34,34,0) 0%, rgba(34,34,34,0) 60%, rgba(34,34,34,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#b3222222',GradientType=0 ); /* IE6-9 */
    @include desktop {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#222222+0,222222+100&0+60,0.7+100 */
      background: -moz-linear-gradient(top,  rgba(34,34,34,0) 0%, rgba(34,34,34,0) 60%, rgba(34,34,34,0.7) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(34,34,34,0) 0%,rgba(34,34,34,0) 60%,rgba(34,34,34,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#b3222222',GradientType=0 ); /* IE6-9 */
    }
   }
   &-photo {
		@include size(100%, 100%);	
		@include absolute(left 0 top 0);
		opacity: 1;
		z-index: 1;
		&-pic {
			@include size(100%);	
			object-fit: cover;
			overflow: hidden;	
   &.opacity-70 {
      opacity: .7;
   }
   @include desktop {
			  @include absolute(left 0 top 0);
   }
  }
		&-img {
			@include size(100%);	
			object-fit: cover;
         opacity: 0;
         transition: $slow;
         transition-delay: 0.25s;
         &.reveal {
           opacity: 1;
         }
		}				
	}

 & .hero-photo {
   position: relative;
		 @include desktop {
		   position: absolute;
		 }
 }

}

